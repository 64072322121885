<template>
  <span class="license">
    <a href="https://creativecommons.org/licenses/by-nc-nd/3.0/es/deed.en">
      Creative Commons license (CC BY-NC-ND 3.0 ES)
    </a>
    <license-icons/>
  </span>
</template>
<script>
  import LicenseIcons from './LicenseIcons'

  export default {
    components: {LicenseIcons}
  }
</script>
